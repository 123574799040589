<template>
  <section class="conOfProductItem pt-5 h-100">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="sectionHeaderWithSub">
            All Products
            <router-link class="eachMapLink" :to="{ name: 'settingProducts' }">
              System Settings
            </router-link>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          lg="3"
          md="4"
          sm="6"
          cols="12"
          v-for="product in products"
          :key="product.id"
        >
          <router-link
            :to="{
              name: product.routerName,
              params: { id: '1' },
            }"
          >
            <v-card
              class="mx-auto pa-5 fill-height conCard"
              max-width="100%"
              elevation="1"
              tile
            >
              <div
                class="
                  fill-height
                  conOfCard
                  d-flex
                  align-center
                  justify-space-around
                "
              >
                <div class="conImg">
                  <img
                    :src="require(`@/assets/img/productIcons/${product.icon}`)"
                    alt=""
                  />
                </div>
                <div class="conTxt">
                  {{ product.name }}
                </div>
              </div>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
// import { ApiService } from "@/modules/shared/services/api";
// let apiServices = new ApiService();
export default {
  data: () => ({
    products: [
      {
        id: 1,
        name: "PAYER",
        icon: "INFUSIONCENTER.png",
        routerName: "allProductsPayers",
      },
      {
        id: 2,
        name: "CITY",
        icon: "city.png",
        routerName: "allProductsCity",
      },
      // { id: 3, name: "DOT", icon: "dots.png", routerName: "allProductsDot" },
      // {
      //   id: 4,
      //   name: "STATUS",
      //   icon: "status.png",
      //   routerName: "allProductsStatus",
      // },
    ],
  }),

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "./_allProductsSetting.scss";
</style>
